.landing {
  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
    align-items: center;
  }
}
