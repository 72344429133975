@import "~react-image-gallery/styles/css/image-gallery.css";

.gallery-container {
  padding: 30px;
  background: linear-gradient(-45deg, #173e88, #12346d);
  padding: 50px;
  height: 100%;
  min-height: 250px;
}

.slides {
  width: 50%;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .slides {
    width: 100%;
  }
  .gallery-container {
    padding: 20px;
  }
}

.middle-footer {
  display: grid;
  place-items: center;
  padding: 20px;
}



.share-icon {
  position: fixed;
  bottom: 25px;
  right: 25px;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 70px;
  border-radius: 10%;
  padding: 8px;
  background-color: rgb(0, 73, 168);
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
}