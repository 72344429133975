.uqr {
  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    background-color: #f2f2f2;
    border: 1px dashed black;
    padding: 10px;
  }
  &__id {
    padding: 10px;
  }
  &__btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  &__qrcode {
    transform: scale(0.5);
  }
}
