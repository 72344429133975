.admin {
  &_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 20px;
  }
  &__form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
    width: 80%;
    background-color: lightgray;
    &__fields {
      display: flex;
      flex-direction: column;
      padding: 20px;
      width: 100%;
    }
  }
}
